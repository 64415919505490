<template>
    <div>
        <strong>Company Upload</strong>
        <br>
        <div class="form-group">
            <label>Company</label>
            <Typeahead ref="companyFileHandlerCompany" cdi_type="company" v-bind:ID.sync="state.referenceID" />
        </div>
        <div class="form-group">
            <label>Title</label>
            <input type="text" v-model="state.fileName" class="form-control" />
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Company",
        components: {
            Typeahead
        },
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize();
        },
        mounted() {
            this.$refs.companyFileHandlerCompany.$data.object = this.$appStore.state.fileUploadContextData.data.company_name;
        }
    }
</script>

<style scoped>

</style>